<template>
    <div>
        <section class="features-details-section">
            <div class="features-overview-menu-wrapper">
                <!-- Menu -->
                <div class="features-overview-menu">
                    <div class="menu-item"
                            :class="{ 'active': showReports }"
                            @click="SetFeatureActiveState('reports')">
                            Reports
                    </div>
                    <div class="menu-item"
                            :class="{ 'active': showApprovals }"
                            @click="SetFeatureActiveState('approvals')">
                            Approvals
                    </div>
                    <div class="menu-item"
                            :class="{ 'active': showInstances }"
                            @click="SetFeatureActiveState('instances')">
                            Instances
                    </div>
                    <div class="menu-item"
                            :class="{ 'active': showSubTenants }"
                            @click="SetFeatureActiveState('sub-tenants')">
                            Sub Tenants
                    </div>
                    <div class="menu-item"
                            :class="{ 'active': showInstanceLibrary }"
                            @click="SetFeatureActiveState('marketplace')">
                            Marketplace
                    </div>
                    <div class="menu-item"
                            :class="{ 'active': showBilling }"
                            @click="SetFeatureActiveState('billing')">
                            Billing
                    </div>
                    <div class="menu-item"
                            :class="{ 'active': showSupport }"
                            @click="SetFeatureActiveState('support')">
                            Support
                    </div>
                    <div class="menu-item"
                            :class="{ 'active': showSettings }"
                            @click="SetFeatureActiveState('settings')">
                            Settings
                    </div>
                </div>
            </div>
            <div class="container">
                <div class="features-overview-wrapper">
                    <!-- Content -->
                    <div class="feature-content-wrapper">
                        <!-- Reports -->
                        <div class="feature reports" v-show="showReports">
                            <p class="feature-strapline">
                                The ability to accurately report on any situation is an imperative part of any team, and cloud is no exception, the more dynamic the situation the more critical that reporting is exact, and timely, as without this matters can quickly fall out of compliance, and this usually means exposure either financially or technically.
                            </p>
                            <!-- Create Tailored Reports -->
                            <div class="row">
                                <div class="column">
                                    <div class="section-block">
                                        <h2 class="section-title">Create Tailored Reports</h2>
                                        <p class="section-description">
                                            Custom reports can be created using a simple input form, select date ranges, cloud types, resource groups to produce a statement of account against the selected parameters. This is particularly useful when interrogating a subset of the overall environment, as reports can be more granular than billing data.
                                        </p>
                                    </div>
                                </div>
                                <div class="column first">
                                    <div class="section-block illustration">
                                        <img src="/images/features/reports/create-tailored-reports.webp" alt="create-tailored-reports.webp">
                                    </div>
                                </div>
                            </div>
                            <!-- Filter Your Reports -->
                            <div class="row">
                                <div class="column first">
                                    <div class="section-block">
                                        <h2 class="section-title">Filter Your Reports</h2>
                                        <p class="section-description">
                                            Unlike billing data, reports are perpetual therefore the ability to filter historical report summaries to compare different data ranges is imperative to build up an accurate picture of changes or anomalies in the environment.
                                        </p>
                                    </div>
                                </div>
                                <div class="column">
                                    <div class="section-block illustration">
                                        <img src="/images/features/reports/filter-your-reports.webp" alt="filter-your-reports.webp">
                                    </div>
                                </div>
                            </div>
                            <!-- In-Depth Report Details -->
                            <div class="row">
                                <div class="column">
                                    <div class="section-block">
                                        <h2 class="section-title">In-Depth Report Details</h2>
                                        <p class="section-description">
                                            Drill down from the summary page to examine the selected environment in greater detail, quickly identify connected cloud costs, and inventories to confirm that the results are in line with expectations.
                                        </p>
                                    </div>
                                </div>
                                <div class="column first">
                                    <div class="section-block illustration">
                                        <img src="/images/features/reports/report-details.webp" alt="report-details.webp">
                                    </div>
                                </div>
                            </div>
                            <!-- Export to CSV -->
                            <div class="row">
                                <div class="column first">
                                    <div class="section-block">
                                        <h2 class="section-title">Export to CSV</h2>
                                        <p class="section-description">
                                            By default each report can be exported externally as a .csv file to be manually imported to an accounting system. Alternatively if an accounting system supports API integration then the export and import function can be fully automated.
                                        </p>
                                    </div>
                                </div>
                                <div class="column">
                                    <div class="section-block illustration">
                                        <img src="/images/features/reports/export-to-csv.webp" alt="export-to-csv.webp">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- Approvals -->
                        <div class="feature approvals" v-show="showApprovals">
                            <p class="feature-strapline">
                                The channel typically works on credit, whereas the cloud uses credit card or direct debit, this creates a conundrum of how do you create a self-service model using credit without risk of unauthorised expenditure. Illapa creates policy through user profiles and uses this to determine customisable approval processes where no resource can be provisioned without the appropriate authorisation.
                            </p>
                            <!-- Set Approvers -->
                            <div class="row">
                                <div class="column">
                                    <div class="section-block">
                                        <h2 class="section-title">Set Approvers</h2>
                                        <p class="section-description">
                                            Authorisation policies are established through user roles, and each user role can be associated to a single or group of approvers. Resources requests are made through the same provisioning process, regardless of user role, but as opposed to the request being fulfilled immediately, if approval is required the resource will be held in a pending state until authorised.
                                        </p>
                                    </div>
                                </div>
                                <div class="column first">
                                    <div class="section-block illustration">
                                        <img src="/images/features/approvals/set-approvers.webp" alt="set-approvers.webp">
                                    </div>
                                </div>
                            </div>
                            <!-- Approve Instance Requests for Users -->
                            <div class="row">
                                <div class="column first">
                                    <div class="section-block">
                                        <h2 class="section-title">Approve Instance Requests for Users</h2>
                                        <p class="section-description">
                                            An approver has three options when a request is made: Approve, Deny, or Cancel, and a full audit trail is available as to who made the decision and when. Prior to any decision the approver is able to interrogate the request to identify the requester, application, operating system, cloud, tenure and cost, enabling a rapid informed decision.
                                        </p>
                                    </div>
                                </div>
                                <div class="column">
                                    <div class="section-block illustration">
                                        <img src="/images/features/approvals/approve-deny-request.webp" alt="approve-deny-request.webp">
                                    </div>
                                </div>
                            </div>
                            <!-- Track and Review Instance Approval Status -->
                            <div class="row">
                                <div class="column">
                                    <div class="section-block">
                                        <h2 class="section-title">Track and Review Instance Approval Status</h2>
                                        <p class="section-description">
                                            Both the requester and the approver have the ability to review both current and historical requests, the requester will only see their own requests, where as the approver will see all the requests that they are the assigned approver of. This provides a quick view of any/all outstanding requests, and also an audit trail of actions.
                                        </p>
                                    </div>
                                </div>
                                <div class="column first">
                                    <div class="section-block illustration">
                                        <img src="/images/features/approvals/view-approval-status.webp" alt="view-approval-status.webp">
                                    </div>
                                </div>
                            </div>
                            <!-- Notifications for Approval Changes -->
                            <div class="row">
                                <div class="column first">
                                    <div class="section-block">
                                        <h2 class="section-title">Notifications for Approval Changes</h2>
                                        <p class="section-description">
                                            Transparency is key here, and both the requester and the approver are notified of the others actions, this again provides a trail to ensure that any SLAs are being met.
                                        </p>
                                    </div>
                                </div>
                                <div class="column">
                                    <div class="section-block illustration">
                                        <img src="/images/features/approvals/notify-user.webp" alt="notify-user.webp">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- Instances -->
                        <div class="feature instances" v-show="showInstances">
                            <p class="feature-strapline">
                                Opposed to static long term contracts, cloud is dynamic by nature, and therefore the day to day management of provisioned resources needs to reflect this, illapa not only enables a change of state, but tracks all changes on the fly to provide a comprehensive audit trail, and immediately updates billing data with the amendments.
                            </p>
                            <!-- Create Instances -->
                            <div class="row">
                                <div class="column">
                                    <div class="section-block">
                                        <h2 class="section-title">Create Instances</h2>
                                        <p class="section-description">
                                            Resources can be provisioned from a single pane to any connected cloud, and where possible regardless of the cloud type, the provisioning process is the same. This dramatically reduces retraining time when an organisation transitions to a hybrid multi-cloud environment, and with guard rails established (as part of the user roles) it delivers on the promise of democratised IT, but irradicates any unauthorised expenditure.
                                        </p>
                                    </div>
                                </div>
                                <div class="column first">
                                    <div class="section-block illustration">
                                        <img src="/images/features/instances/create-instances.webp" alt="create-instances.webp">
                                    </div>
                                </div>
                            </div>
                            <!-- Manage Instances -->
                            <div class="row">
                                <div class="column first">
                                    <div class="section-block">
                                        <h2 class="section-title">Manage Instances</h2>
                                        <p class="section-description">
                                            As our businesses evolve so do our requirements, therefore the ability to quickly identify whether the current provisioned resources still meet your business needs is imperative. This has also been exasperated as we shift from a CapEX/OpEx model to an OpEx only model as any excess resources are quickly translated into unnecessary costs, this issue here though is identifying the culprits from the monthly invoice.
                                        </p>
                                    </div>
                                </div>
                                <div class="column">
                                    <div class="section-block illustration">
                                        <img src="/images/features/instances/manage-instances.webp" alt="manage-instances.webp">
                                    </div>
                                </div>
                            </div>
                            <!-- Instance Details -->
                            <div class="row">
                                <div class="column">
                                    <div class="section-block">
                                        <h2 class="section-title">Instance Details</h2>
                                        <p class="section-description">
                                            A constant review of what was bought, when and by who, along with any amendments made as part of lifecycle management of the provisioned resource. This will identify any changes in service plan, storage, and currency fluctuations.
                                        </p>
                                    </div>
                                </div>
                                <div class="column first">
                                    <div class="section-block illustration">
                                        <img src="/images/features/instances/instance-details.webp" alt="instance-details.webp">
                                    </div>
                                </div>
                            </div>
                            <!-- Edit Instances -->
                            <div class="row">
                                <div class="column first">
                                    <div class="section-block">
                                        <h2 class="section-title">Edit Instances</h2>
                                        <p class="section-description">
                                            Provisioned resources can be edited through the summary page, or instance details, quickly and easily change service plans, add storage volumes, or change expiry dates to ensure that the resources are meeting the needs of the business. See immediately the impact on cost any change to the instance specification makes.
                                        </p>
                                    </div>
                                </div>
                                <div class="column">
                                    <div class="section-block illustration">
                                        <img src="/images/features/instances/edit-instances.webp" alt="edit-instances.webp">
                                    </div>
                                </div>
                            </div>
                            <!-- Console Onto Instances -->
                            <div class="row">
                                <div class="column">
                                    <div class="section-block">
                                        <h2 class="section-title">Console Onto Instances</h2>
                                        <p class="section-description">
                                            Having provisioned a resource the next logical process is to console on to the resource so that it can be used. Our built-in browser-based console will connect via SSH, VNC or RDP depending upon your machine type. The browser can be dynamically resized to meet the needs of the user.
                                        </p>
                                    </div>
                                </div>
                                <div class="column first">
                                    <div class="section-block illustration">
                                        <img src="/images/features/instances/console-onto-instances.webp" alt="console-onto-instances.webp">
                                    </div>
                                </div>
                            </div>
                            <!-- Set Minimum Specs for Custom Instance Types -->
                            <div class="row">
                                <div class="column first">
                                    <div class="section-block">
                                        <h2 class="section-title">Set Minimum Specs for Custom Instance Types</h2>
                                        <p class="section-description">
                                            To create pricing parity between procurement, management, and billing, we manage all minimum machine specifications, along with approved service plans. This not only reduces that amount of noise pollution where there are potentially thousands of service plans to choose from, but also pre-populates the provisioning process so that the user can never build a machine that doesn't meet the minimum specified resources.
                                        </p>
                                    </div>
                                </div>
                                <div class="column">
                                    <div class="section-block illustration">
                                        <img src="/images/features/instances/minspecs.webp" alt="minspecs.webp">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- Sub Tenants -->
                        <div class="feature sub-tenants"
                             v-show="showSubTenants">
                             <p class="feature-strapline">
                                 These can be temporary projects, or a perpetual line of business as technically each sub-tenant will be logically separated by billing, network and datastore. This can be used to set and manage user groups and budgets that could be used as a cost of sale against a revenue generating project, or fully automate show back/chargeback accounting to a line of business.
                             </p>
                            <!-- Create Sub Tenants -->
                             <div class="row">
                                 <div class="column">
                                     <div class="section-block">
                                         <h2 class="section-title">Create Sub Tenants</h2>
                                         <p class="section-description">
                                             Each account can be a master tenant, and each master tenant has the ability to create sub tenants, this will enable an MSP to add external clients, and/or an IT department to add LOBs. Accounts are not limited to the number of sub-tenants that they can create, therefore the billing construct can be as granular as necessary.
                                         </p>
                                     </div>
                                 </div>
                                 <div class="column first">
                                     <div class="section-block illustration">
                                         <img src="/images/features/sub-tenants/create-sub-tenants.webp" alt="create-sub-tenants.webp">
                                     </div>
                                 </div>
                             </div>
                             <!-- Manage Your Sub Tenants -->
                             <div class="row">
                                 <div class="column first">
                                     <div class="section-block">
                                         <h2 class="section-title">Manage Your Sub Tenants</h2>
                                         <p class="section-description">
                                             A master tenant has the ability to manage a sub tenant, and therefore each sub tenant can have different currency, time zones, clouds, marketplace items and pricing. Once setup the sub tenant will be able to consume resources made available to them by the master tenant.
                                         </p>
                                     </div>
                                 </div>
                                 <div class="column">
                                     <div class="section-block illustration">
                                         <img src="/images/features/sub-tenants/manage-sub-tenants.webp" alt="manage-sub-tenants.webp">
                                     </div>
                                 </div>
                             </div>
                            <!-- Impersonate Your Sub Tenants -->
                             <div class="row">
                                 <div class="column">
                                     <div class="section-block">
                                         <h2 class="section-title">Impersonate Your Sub Tenants</h2>
                                         <p class="section-description">
                                             For setup and troubleshooting the master tenant has the ability to impersonate a user in the form of a sub tenant, this enables the system admin to move seamlessly between clients without having to log out and back in to each individual sub tenant.
                                         </p>
                                     </div>
                                 </div>
                                 <div class="column first">
                                     <div class="section-block illustration">
                                         <img src="/images/features/sub-tenants/impersonate-sub-tenants.webp" alt="impersonate-sub-tenants.webp">
                                     </div>
                                 </div>
                             </div>
                             <!-- Get Detailed Sub Tenant Billing Reports -->
                             <div class="row">
                                 <div class="column first">
                                     <div class="section-block">
                                         <h2 class="section-title">Get Detailed Sub Tenant Billing Reports</h2>
                                         <p class="section-description">
                                             A master tenant will also be able to interrogate billing data from their sub tenants, a master could be an MSP with external client, or an Enterprise with internal LOBs, extending the transparency, and radically simplifying invoicing or cross-charging.
                                         </p>
                                     </div>
                                 </div>
                                 <div class="column">
                                     <div class="section-block illustration">
                                         <img src="/images/features/sub-tenants/sub-tenant-billing-reports.webp" alt="sub-tenant-billing-reports.webp">
                                     </div>
                                 </div>
                             </div>
                        </div>
                        <!-- Marketplace -->
                        <div class="feature marketplace"
                             v-show="showInstanceLibrary">
                             <p class="feature-strapline">
                                 The key differentiator of our marketplace is that from this single library a user can provision to any cloud public or private anywhere in the world. We do not do license management that is the role of our partners, we build and maintain the instance types, cloud layouts, and virtual images including version control so you can experience a simple, repeatable process from a common UI regardless of your choice of infrastructure.
                             </p>
                            <!-- View Your Marketplace -->
                             <div class="row">
                                 <div class="column">
                                     <div class="section-block">
                                         <h2 class="section-title">View Your Marketplace</h2>
                                         <p class="section-description">
                                             Everything in our marketplace is delivered as BYOL, as we are expecting either the channel partner, or the end user to bring the license, in this way we maintain the status quo of the current channel structure. We maintain a library of virtual images, cloud layouts and instance types for partners to select, and user privileges determine which are visible to any user, in any account in any deployment.
                                         </p>
                                     </div>
                                 </div>
                                 <div class="column first">
                                     <div class="section-block illustration">
                                         <img src="/images/features/instance-library/view-your-marketplace.webp" alt="view-your-marketplace.webp">
                                     </div>
                                 </div>
                             </div>
                             <!-- Add Your Favourite Providers -->
                             <div class="row">
                                 <div class="column first">
                                     <div class="section-block">
                                         <h2 class="section-title">Add Your Favourite Providers</h2>
                                         <p class="section-description">
                                             Library items can be shared down from our master library, or added at a tenant level, or any combination of the two. This enables system admins to truly customise the applications and operating systems offered to clients included in any bespoke applications.
                                         </p>
                                     </div>
                                 </div>
                                 <div class="column">
                                     <div class="section-block illustration">
                                         <img src="/images/features/instance-library/add-favourites.webp" alt="add-favourites.webp">
                                     </div>
                                 </div>
                             </div>
                             <!-- Style How Your Marketplace Looks -->
                             <div class="row">
                                 <div class="column">
                                     <div class="section-block">
                                         <h2 class="section-title">Style How Your Marketplace Looks</h2>
                                         <p class="section-description">
                                             For that truly unique visual experience, for any deployment each marketplace item can have its own image, this will enable the channel partner to brand everything offered in their marketplace with their own branding.
                                         </p>
                                     </div>
                                 </div>
                                 <div class="column first">
                                     <div class="section-block illustration">
                                         <img src="/images/features/instance-library/full-control.webp" alt="full-control.webp">
                                     </div>
                                 </div>
                             </div>
                        </div>
                        <!-- Billing -->
                        <div class="feature billing"
                             v-show="showBilling">
                             <p class="feature-strapline">
                                 The ability to reconcile an invoice against a purchase order appears to have been lost as we transition from a transfer of title to a subscription business model. As we have complete transparency across the three pillars of procurement, management and finance, along with dynamic updates it is really simple now for finance teams to compare billing data to procurement. Any discrepancies can be easily identified through audit trails and billing detail.
                             </p>
                            <!-- Run Billing Reports -->
                             <div class="row">
                                 <div class="column">
                                     <div class="section-block">
                                         <h2 class="section-title">Run Billing Reports</h2>
                                         <p class="section-description">
                                             Reports can be run on a per-tenant or pre-machine basis, and although the date range defaults to the current month, any date and time range can be selected. Billing data correlates to Create Instance, and Manage Instance data providing consistency from procurement through to invoicing.
                                         </p>
                                     </div>
                                 </div>
                                 <div class="column first">
                                     <div class="section-block illustration">
                                         <img src="/images/features/billing/run-billing-report.webp" alt="run-billing-report.webp">
                                     </div>
                                 </div>
                             </div>
                             <!-- Get Detailed Sub Tenant Billing Reports -->
                             <div class="row">
                                 <div class="column first">
                                     <div class="section-block">
                                         <h2 class="section-title">Get Detailed Sub Tenant Billing Reports</h2>
                                         <p class="section-description">
                                             A master tenant will also be able to interrogate billing data from their sub tenants, a master could be an MSP with external client, or an Enterprise with internal LOBs, extending the transparency, and radically simplifying invoicing or cross-charging.
                                         </p>
                                     </div>
                                 </div>
                                 <div class="column">
                                     <div class="section-block illustration">
                                         <img src="/images/features/sub-tenants/sub-tenant-billing-reports.webp" alt="sub-tenant-billing-reports.webp">
                                     </div>
                                 </div>
                             </div>
                             <!-- Export to CSV -->
                             <div class="row">
                                 <div class="column">
                                     <div class="section-block">
                                         <h2 class="section-title">Export to CSV</h2>
                                         <p class="section-description">
                                             By default each billing report can be exported externally as a .csv file to be manually imported to an accounting system. Alternatively if an accounting system supports API integration then the export and import function can be fully automated.
                                         </p>
                                     </div>
                                 </div>
                                 <div class="column first">
                                     <div class="section-block illustration">
                                         <img src="/images/features/billing/export-to-csv.webp" alt="export-to-csv.webp">
                                     </div>
                                 </div>
                             </div>
                        </div>
                        <!-- Support -->
                        <div class="feature support" v-show="showSupport">
                            <p class="feature-strapline">
                                We all understand the value of self-service portals, and the ability to be able to consume services when it suits us, as opposed to being driven by somebody else's timeframe. The problem usually occurs with the 5-20% of functions that can't be delivered through the self-service, and you need to speak with a human. With a built in help desk, assistance is available at the click of a button, with 1st, 2nd and 3rd line support separated so 1st and 2nd line support can be delivered by the MSP or IT department.
                            </p>
                            <!-- Create Tickets -->
                            <div class="row">
                                <div class="column">
                                    <div class="section-block">
                                        <h2 class="section-title">Create Tickets</h2>
                                        <p class="section-description">
                                            There is no necessity to leave the UI to be able to raise a service desk ticket, each ticket is categorised and weighted to ensure necessary response times are achieved. An audit trail of the ticket is logged both in the UI and also in a separate email trail.
                                        </p>
                                    </div>
                                </div>
                                <div class="column first">
                                    <div class="section-block illustration">
                                        <img src="/images/features/support/create-a-ticket.webp" alt="create-a-ticket.webp">
                                    </div>
                                </div>
                            </div>
                            <!-- Chat with Support Team -->
                            <div class="row">
                                <div class="column first">
                                    <div class="section-block">
                                        <h2 class="section-title">Chat with Support Team</h2>
                                        <p class="section-description">
                                            To enhance the user experience there is a chat style interaction between the support team and the user, enabling the user to continue with their operations whilst receiving time and date stamped responses from the support team, ensuring that SLAs are always met.
                                        </p>
                                    </div>
                                </div>
                                <div class="column">
                                    <div class="section-block illustration">
                                        <img src="/images/features/support/chat-with-support.webp" alt="chat-with-support.webp">
                                    </div>
                                </div>
                            </div>
                            <!-- Receive Email Updates from Our Team -->
                            <div class="row">
                                <div class="column">
                                    <div class="section-block">
                                        <h2 class="section-title">Receive Email Updates from Our Team</h2>
                                        <p class="section-description">
                                            Everybody has a choice, and each support ticket will also have an accompanying email, the information delivered is the same as the chat experience, and the chat history will still exist, but if the user preference is to email then no problem.
                                        </p>
                                    </div>
                                </div>
                                <div class="column first">
                                    <div class="section-block illustration">
                                        <img src="/images/features/support/receive-email-updates.webp" alt="receive-email-updates.webp">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- Settings -->
                        <div class="feature settings" v-show="showSettings">
                            <p class="feature-strapline">
                                A deployment is a hosted webserver running our OpsMgr application, each deployment can support multiple accounts (tenancies), settings made at the deployment (global) level will be consistent across all accounts, however each account can have its own bespoke settings, and these are unique to the account.
                            </p>
                            <!-- Whitelabel Features -->
                            <div class="row">
                                <div class="column">
                                    <div class="section-block">
                                        <h2 class="section-title global-settings">Whitelabel Features</h2>
                                        <p class="section-description">
                                            Style your deployment to reflect your brand; upload logos and amend colour palettes in the UI to build a truly bespoke client experience, this is what users will see when they login.
                                        </p>
                                    </div>
                                </div>
                                <div class="column first">
                                    <div class="section-block illustration">
                                        <img src="/images/features/settings/whitelabel.webp" alt="whitelabel.webp">
                                    </div>
                                </div>
                            </div>
                            <!-- Service Plan Pricing -->
                            <div class="row">
                                <div class="column first">
                                    <div class="section-block">
                                        <h2 class="section-title account-settings">Service Plan Pricing</h2>
                                        <p class="section-description">
                                            We normalise the commerical construct between an on-premise private virtualised infrastructure, shared resource hosted virtualised infrastructure, and the hyperscale public cloud providers. On demand public cloud pricing is ingested daily, and bespoke pricing constructs are built for the private and hosted environments so that we now have the cost for each service plan regardless of the provider in the same format. We can then apply discounts and margins to each service plan to reflect the price that is quoted to the client.
                                        </p>
                                    </div>
                                </div>
                                <div class="column">
                                    <div class="section-block illustration">
                                        <img src="/images/features/settings/account-settings.webp" alt="account-settings.webp">
                                    </div>
                                </div>
                            </div>
                            <!-- Set Approvers -->
                            <div class="row">
                                <div class="column">
                                    <div class="section-block">
                                        <h2 class="section-title account-settings">Set Approvers</h2>
                                        <p class="section-description">
                                            Authorisation policies are established through user roles, and each user role can be associated to a single or group of approvers. Resources requests are made through the same provisioning process, regardless of user role, but as opposed to the request being fulfilled immediately, if approval is required the resource will be held in a pending state until authorised.
                                        </p>
                                    </div>
                                </div>
                                <div class="column first">
                                    <div class="section-block illustration">
                                        <img src="/images/features/approvals/set-approvers.webp" alt="set-approvers.webp">
                                    </div>
                                </div>
                            </div>
                            <!-- Set Minimum Specs for Custom Instance Types -->
                            <div class="row">
                                <div class="column first">
                                    <div class="section-block">
                                        <h2 class="section-title account-settings">Set Minimum Specs for Custom Instance Types</h2>
                                        <p class="section-description">
                                            To create pricing parity between procurement, management, and billing, we manage all minimum machine specifications, along with approved service plans. This not only reduces that amount of noise pollution where there are potentially thousands of service plans to choose from, but also pre-populates the provisioning process so that the user can never build a machine that doesn't meet the minimum specified resources.
                                        </p>
                                    </div>
                                </div>
                                <div class="column">
                                    <div class="section-block illustration">
                                        <img src="/images/features/instances/minspecs.webp" alt="minspecs.webp">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>

export default {
    data: () => ({
        activeFeature: "",
        showReports: false, showApprovals: false, showInstances: false,
        showSubTenants: false, showInstanceLibrary: false, showBilling: false,
        showSupport: false, showSettings: false,
    }),
    metaInfo() {
        return {
            title: 'Features | Illapa Cloud',
            meta: [
                {
                    name: 'description',
                    content: 'We create parity within a hybrid multi-cloud environment across three core pillars of business: Procurement, Operations, and Finance, accelerating time to value with your digital transformation. Through our simplified user experience 80% of tasks can now be democratised to the line of business, or external customer, with 20% being delivered from IT specialists. This hybrid approach to a service delivery model achieves the nirvana of a self-service model, but with comprehensive policy management.'
                },
                {
                    property: 'og:title',
                    content: 'Features | Illapa Cloud'
                },
                {
                    property: 'og:description',
                    content: 'We create parity within a hybrid multi-cloud environment across three core pillars of business: Procurement, Operations, and Finance, accelerating time to value with your digital transformation. Through our simplified user experience 80% of tasks can now be democratised to the line of business, or external customer, with 20% being delivered from IT specialists. This hybrid approach to a service delivery model achieves the nirvana of a self-service model, but with comprehensive policy management.'
                },
                {
                    property: 'og:url',
                    content: 'https://illapa.cloud/platform/features'
                }
            ],
            link: [
                {
                    rel: 'canonical',
                    href: 'https://illapa.cloud/platform/features'
                }
            ]
        }
    },
    created() {
        if (this.$route.query.feature == undefined) {
            this.activeFeature = 'reports';
        } else {
            this.activeFeature = this.$route.query.feature;
        }
    },
    watch: {
        activeFeature: function () {
            this.ResetFeatureActiveStates();
            switch(this.activeFeature) {
                case "reports":
                    this.showReports = true;
                    break;
                case "approvals":
                    this.showApprovals = true;
                    break;
                case "instances":
                    this.showInstances = true;
                    break;
                case "sub-tenants":
                    this.showSubTenants = true;
                    break;
                case "marketplace":
                    this.showInstanceLibrary = true;
                    break;
                case "billing":
                    this.showBilling = true;
                    break;
                case "support":
                    this.showSupport = true;
                    break;
                case "settings":
                    this.showSettings = true;
                    break;
                default:
                    break;
            }
        },
        $route: function () {
            if (this.$route.query.feature == undefined) {
                this.activeFeature = 'reports';
            } else {
                this.activeFeature = this.$route.query.feature;
            }
        },
    },
    methods: {
        ResetFeatureActiveStates() {
            this.showReports = false;
            this.showApprovals = false;
            this.showInstances = false;
            this.showSubTenants = false;
            this.showInstanceLibrary = false;
            this.showBilling = false;
            this.showSupport = false;
            this.showSettings = false;
        },
        SetFeatureActiveState(feature) {
            this.activeFeature = feature;
            this.$router.replace({ name: this.$router.name, query: { feature: feature } });
        },
    },
}
</script>

<style scoped>
.features-overview-menu-wrapper {
    padding: 0 15px;
}
.features-overview-menu-wrapper .features-overview-menu {
    display: flex;
    align-items: center;
    max-width: 900px;
    margin: 100px auto 70px auto;
    padding: 10px 0;
    overflow-x: auto;
}
.features-overview-menu-wrapper .features-overview-menu .menu-item {
    position: relative;
    margin: 0 15px;
    padding: 5px 0;
    font-size: 18px;
    font-weight: 300;
    color: var(--text-dark-terniary);
    user-select: none;
    cursor: pointer;
    white-space: nowrap;
    transition: color 0.2s ease,
                transform 0.2s ease;
}
.features-overview-menu-wrapper .features-overview-menu .menu-item:hover,
.features-overview-menu-wrapper .features-overview-menu .menu-item.active {
    color: var(--text-dark-primary);
    transform: translateY(-5px);
}
.features-overview-menu-wrapper .features-overview-menu .menu-item::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    width: 0%;
    height: 2px;
    background-color: var(--brand-primary-blue);
    transition: width 0.2s ease;
}
.features-overview-menu-wrapper .features-overview-menu .menu-item.active::after {
    width: 25px;
}
.features-overview-wrapper .feature-content-wrapper {
    margin: 0 auto 100px auto;
}
.features-overview-wrapper .feature-content-wrapper .feature p {
    font-weight: 300;
    line-height: 1.8;
    margin: 0 0 30px 0;
    font-size: 18px;
}
.features-overview-wrapper .feature-content-wrapper .feature .feature-strapline {
    color: var(--text-dark-primary);
    margin-bottom: 70px;
    margin-left: auto;
    margin-right: auto;
    max-width: 900px;
}
.row {
    display: flex;
    flex-wrap: wrap;
    padding: 60px 0;
    max-width: 1140px;
    margin: 0 auto;
}
.column {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
}
.section-block {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.section-block.illustration {
    overflow: hidden;
}
.column.first .section-block.illustration {
    align-items: flex-start;
}
.column:not(.first) .section-block.illustration {
    align-items: flex-end;
}
.section-block.illustration img {
    object-fit: contain;
    max-width: 512px;
    width: 100%;
}
.section-block .section-title {
    position: relative;
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 40px;
    font-size: 32px;
    font-weight: 400;
    color: var(--text-dark-primary);
    line-height: 44px;
    width: 100%;
    max-width: 750px;
}
.section-block .section-title::before {
    position: absolute;
    top: -30px;
    left: 0;
    width: 100%;
    text-transform: capitalize;
    font-weight: 300;
    color: var(--brand-accent-blue);
    font-size: 14px;
    letter-spacing: 3px;
}
.feature.reports .section-block .section-title::before {
    content: "REPORTS";
}
.feature.approvals .section-block .section-title::before {
    content: "APPROVALS";
}
.feature.instances .section-block .section-title::before {
    content: "INSTANCES";
}
.feature.sub-tenants .section-block .section-title::before {
    content: "SUB TENANTS";
}
.feature.marketplace .section-block .section-title::before {
    content: "MARKETPLACE";
}
.feature.billing .section-block .section-title::before {
    content: "BILLING";
}
.feature.support .section-block .section-title::before {
    content: "SUPPORT";
}
.feature.settings .section-block .section-title::before {
    content: "SETTINGS";
}
.feature.settings .section-block .section-title.global-settings::before {
    content: "GLOBAL SETTINGS";
}
.feature.settings .section-block .section-title.account-settings::before {
    content: "ACCOUNT SETTINGS";
}
.section-block .section-description {
    font-size: 18px;
    line-height: 32px;
    color: var(--text-dark-terniary);
}
@media only screen and (max-width: 700px) {
    .features-overview-menu-wrapper .features-overview-menu {
        margin-top: 50px;
    }
}
@media only screen and (min-width: 860px) {
    .features-overview-menu-wrapper .features-overview-menu {
        justify-content: space-evenly;
    }
}
@media only screen and (max-width: 1023px) {
    .row {
        flex-direction: column;
        padding: 0 0 60px 0;
    }
    .column {
        flex: 0 0 100%;
        width: 100%;
    }
    .section-block {
        align-items: center;
    }
    .section-block .section-title {
        font-size: 22px;
        margin-bottom: 1rem;
    }
    .section-block.illustration {
        align-items: center !important;
    }
}
@media only screen and (min-width: 1023px) {
    .column {
        flex: 0 0 50%;
        max-width: 50%;
    }
    .column.first {
        order: -1;
    }
    .section-block {
        min-height: 430px;
    }
    .section-block .section-title,
    .section-block .section-description {
        text-align: left;
    }
}
</style>